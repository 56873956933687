<template>
  <footer>
    <div class="footer-wrap">
      <div class="container mb-3">
        <div class="abbv-blk">
          <small class="d-block"><sup>††</sup>Offre valable jusqu’à épuisement des stocks. Soumettez une preuve d’achat en ligne pour recevoir un livre. Les deux boîtes doivent être achetées au cours d’une même transaction, effectuée au plus tard à 23&nbsp;h&nbsp;59&nbsp;min&nbsp;59&nbsp;s&nbsp;(HE) le 31&nbsp;mars&nbsp;2025. La preuve d’achat doit indiquer clairement le nom du détaillant, la date de l’achat, les produits participants achetés et le montant total de la transaction en dollars, et être envoyée au plus tard à 23&nbsp;h&nbsp;59&nbsp;min&nbsp;59&nbsp;s (HE) le 30&nbsp;avril&nbsp;2025. Les preuves d’achat ne peuvent être présentées qu’une seule fois et sont vérifiées. Limite de cinq (5) livres par foyer. Total de livres offerts: 30&nbsp;000. Les titres des livres sont sous réserve de disponibilité. Vous recevrez votre livre par la poste dans un délai de 3 à 5 semaines environ. WK Kellogg Canada ne peut être tenue responsable de tout envoi perdu, volé, mal acheminé ou endommagé. L’offre est valable uniquement pour les adresses de livraison au Canada. Les participants doivent être majeurs. Les couvertures et les livres inclus dans ce programme sont la propriété intellectuelle de Penguin Random House Canada ou leur utilisation est autorisée sous licence par Penguin Random House Canada.</small>
        </div>
      </div>
      <div class="footer-bar">
        <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/footer-bar.png"
          alt="bar" class="img-fluid" width="100%">
      </div>
      <div class="container-fluid">
        <div class="navbar">
          <div class="col-sm-12 p-0 text-center">
            <ul class="list-inline mb-0 small" id="footer-nav">
              <li class="list-inline-item">
                <a href="https://snipp.us/!ctbeL" target="_blank">Modalités</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/fr/avis-de-respect-de-la-vie-privee" target="_blank">Avis de confidentialité</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/fr/conditions-utilisation" target="_blank">Conditions d’utilisation</a>
              </li>
              <li>
                <a nohref="nohref" class="ot-sdk-show-settings" style="cursor: pointer">Préférences relatives aux témoins</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/fr/accessibilite" target="_blank">Accessibilité</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/fr/" target="_blank">WKKellogg.ca</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/fr/communiquez-avec-nous" target="_blank">Communiquez avec nous</a>
              </li>
            </ul>
            <p class="mt-3">
              <small>© WK Kellogg Canada Corp., {{ new Date().getFullYear() }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  color: #fff;
  margin-top: auto;
}

.footer-wrap {
  background-color: #513629;
  padding: 3rem 0;
  position: relative;
}

.footer-wrap:before {
  content: "";
  background-image: url(https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/footer-top.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.footer-bar {
  background-image: url('');
}

#footer-nav li+li:before {
  content: ' | ';
  color: #fff;
  margin-right: 7px;
}

.footer-social-nav .social-icon {
  color: #fff;
  font-size: 2rem;
  border-radius: 5px;
}

.footer-social-nav {
  float: right;
}

.footer-social-nav .social-icon:hover {
  color: #176198;
}

.list-inline>li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

a {
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  margin: 5px 0;
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.abbv-blk {
    color: #a8a8a8;
}
@media screen and (max-width: 575px) {
  .navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #footer-nav li+li:before {
    content: '';
    margin-right: 0px;
  }

  footer {
    text-align: center;
  }

  footer li.list-inline-item {
    display: block;
  }

  footer li.list-inline-item {
    display: block;
  }

  .footer-social-nav {
    float: none;
  }
}
</style>
