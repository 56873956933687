<template>
  <Popup :visible="isPopupVisible" @close="isPopupVisible = false"></Popup>

  <the-navigation></the-navigation>

  <router-view></router-view>

  <the-Footer></the-Footer>
</template>

<script>
import TheNavigation from './components/Layout/TheNavigation.vue';
import TheFooter from './components/Layout/TheFooter.vue';
import { ref, onMounted } from 'vue';
import Popup from './components/Layout/Popup.vue';
export default {
  components: {
    TheNavigation,
    TheFooter,
    Popup,
  },
 setup() {
    const isPopupVisible = ref(false);

    onMounted(() => {
      isPopupVisible.value = true;
    });

    return {
      isPopupVisible,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>
