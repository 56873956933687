<template>
    <div v-if="visible" class="popup-overlay" @click.self="closePopup">
      <div class="popup-content">
        <button class="close-button" @click="closePopup">×</button>
        <slot>Veuillez noter que notre promotion Nourrir la lecture a été prolongée jusqu’au 31&nbsp;mars&nbsp;2025. Tous les achats admissibles effectués au plus tard le 31 mars 2025 seront acceptés. La dernière date pour soumettre un reçu est le 30&nbsp;avril&nbsp;2025.</slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close'],
    methods: {
      closePopup() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }
  
  .popup-content {
    background: white;
    padding: 30px 20px;
    border-radius: 10px;
    max-width: 420px;
    width: 90%;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 2px;
    right: 5px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  </style>
  