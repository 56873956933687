<template>
  <div class="container">
    <div class="row faqs mx-auto mb-5 pb-5">
      <div class="col-md-12">
        <h1 class="text-uppercase text-center mt-5 mb-5">
          Foire aux questions
        </h1>
        <div class="accordion" id="faqAccordion">
          <div class="card" v-for="(faq, index) in faqs" :key="index">
            <div class="card-header" :id="'heading' + index">
              <h2 class="accordion-header">
                <button :class="{
            'arrow-open': activeIndex === index,
            'arrow-closed': activeIndex !== index,
          }" class="accordion-button" type="button" @click="toggle(index)"
                  :aria-expanded="activeIndex === index ? 'true' : 'false'" :aria-controls="'collapse' + index">
                  <span v-html="faq.question"></span>
                  <span></span>
                </button>
              </h2>
            </div>
            <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ show: activeIndex === index }"
              :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
              <div class="card-body" v-html="faq.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question:
            'Quand vais-je recevoir mon livre? ',
          answer:
            'Une fois votre soumission validée, veuillez prévoir de 3 à 5 semaines pour la livraison. Votre livre sera posté à l’adresse que vous avez fournie. ',
        },
        {
          question: 'À combien de livres ai-je droit? ',
          answer:
            'La limite est de cinq (5) livres par foyer.',
        },
        {
          question: 'Quels produits Kellogg’s<sup>®</sup> dois-je acheter pour être admissible à un livre? ',
          answer:
            'L’offre est uniquement valable pour les boîtes de céréales Kellogg’s<sup>®</sup> spécialement marquées. Vous trouverez <a href="https://snipp.us/!ctbeV" target="_blank">ici</a> la liste de toutes les céréales Kellogg’s<sup>®</sup> participantes.',
        },
        {
          question:
            'Dois-je acheter tous les produits en une seule transaction? ',
          answer:
            'Oui, vous devez acheter deux produits participants en une seule transaction. Les achats doivent être effectués au plus tard le 31&nbsp;mars&nbsp;2025. ',
        },
        {
          question: 'Combien de fois puis-je soumettre la même preuve d’achat valide? ',
          answer:
            'Vous ne pouvez soumettre une preuve d’achat valide qu’une seule fois. ',
        },
        {
          question: 'Pourquoi demande-t-on de téléverser chaque preuve d’achat séparément? ',
          answer:
            'Si vous téléversez plus d’une preuve d’achat dans une seule soumission, cette dernière sera refusée et vous serez invité à soumettre chaque document dans une nouvelle soumission distincte. ',
        },
        {
          question: 'Comment puis-je savoir si ma demande de livre a été reçue? ',
          answer: 'Chaque fois que vous téléverserez une preuve d’achat, vous recevrez un courriel vous informant que nous avons reçu votre demande. Si vous ne voyez pas le courriel dans votre boîte de réception, veuillez vérifier vos dossiers de pourriels ou de courriels indésirables, ou tout autre dossier que votre fournisseur de messagerie a configuré automatiquement dans votre compte. ',
        },
        {
          question: 'Que se passe-t-il une fois la demande présentée? ',
          answer:
            'Votre soumission sera examinée, et sera approuvée ou refusée dans les 72 heures. Conservez votre reçu ou votre preuve d’achat jusqu’à ce que vous receviez votre livre par la poste. Veuillez prévoir de 3 à 5 semaines pour la livraison. ',
        },
        {
          question: 'Quelle est la date limite pour soumettre une preuve d’achat? ',
          answer: 'La preuve d’achat doit être soumise d’ici le 30&nbsp;avril&nbsp;2025. Les soumissions seront examinées et approuvées (ou refusées) si elles sont présentées au plus tard à 23&nbsp;h&nbsp;59&nbsp;min&nbsp;59&nbsp;s&nbsp;(HNE). ',
        },
        {
          question:
            'Comment puis-je soumettre une preuve d’achat d’un détaillant en ligne? ',
          answer: 'Les &eacute;tats de commande ne seront pas accept&eacute;s. Vous devez t&eacute;l&eacute;verser une photo ou un fichier&nbsp;PDF de bonne qualit&eacute; de la confirmation de l&rsquo;exp&eacute;dition, de la livraison ou du ramassage de votre commande. Assurez-vous que votre document contient les renseignements suivants: <br />Site Web et nom du d&eacute;taillant&nbsp;<br />Date &agrave; laquelle votre commande a &eacute;t&eacute; pass&eacute;e&nbsp;<br />Date &agrave; laquelle votre commande a &eacute;t&eacute; livr&eacute;e ou ramass&eacute;e&nbsp;<br />Description et quantit&eacute; du ou des produits Kellogg&rsquo;s<sup>&reg;</sup> participants achet&eacute;s&nbsp;<br />Valeur totale en dollars de la transaction compl&egrave;te&nbsp;<br />Adresse d&rsquo;exp&eacute;dition, s&rsquo;il y a lieu&nbsp;',
        },
        {
          question: 'Avec qui puis-je communiquer si j’ai des questions? ',
          answer:
            'Veuillez communiquer avec nous au <a href="https://www.wkkellogg.ca/fr-ca/communiquez-avec-nous.html" target="_blank">https://www.wkkellogg.ca/fr-ca/communiquez-avec-nous.html</a> et indiquez l’adresse électronique que vous avez utilisée pour vous inscrire à la promotion. Incluez votre nom et tout numéro de confirmation de soumission pertinent, le cas échéant. ',
        },
        {
          question:
            'Puis-je obtenir plusieurs livres en un seul achat? ',
          answer: 'Oui. Pour chaque groupe de deux (2) produits admissibles se trouvant sur une preuve d’achat soumise individuellement, vous recevrez un (1) livre, jusqu’à concurrence de cinq (5) livres par personne et par foyer. Par exemple, si vous soumettez une preuve d’achat contenant six (6) produits participants, vous recevrez trois (3) livres. ',
        },
        {
          question: 'Puis-je soumettre mon reçu autrement que par l’entremise du site Web? ',
          answer: 'Si, pour une raison ou une autre, vous ne pouvez pas vous inscrire sur le site Web de la promotion ou t&eacute;l&eacute;verser votre preuve d&rsquo;achat, vous pouvez envoyer cette derni&egrave;re &agrave; l&rsquo;adresse suivante:&nbsp;<br /><br />Offre Nourrir la lecture de WK Kellogg Canada<br />C.P. 250-KG003&nbsp;<br />Pickering, ON<br />L1V&nbsp;2R4&nbsp;<br /><br />La soumission envoy&eacute;e par la poste doit &eacute;galement comprendre les renseignements suivants:&nbsp;<br /><br />Nom complet&nbsp;<br />Adresse postale compl&egrave;te incluant le code postal&nbsp;<br />Adresse &eacute;lectronique valide&nbsp;<br />Preuve d&rsquo;achat originale (conservez une copie pour vos dossiers)<br><br>Indiquez vos trois premiers choix de livres :<br>1<sup>er</sup> choix :<br>2<sup>e</sup> choix :<br>3<sup>e</sup> choix :  ',
        },
      ],
      activeIndex: null,
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
#faqAccordion>.card {
  margin-bottom: 15px;
  border-radius: 0px;
  border: 2px solid #ca1246;
  background: transparent;
}

#faqAccordion>.card>.card-header {
  padding: 0px;
  background: transparent;
  border-color: #ca1246;
}

#faqAccordion .accordion-button {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 600;
  padding: 15px 30px 15px 15px;
  box-shadow: none;
  background: transparent;
}

#faqAccordion .accordion-button:focus,
#faqAccordion .accordion-button.focus,
#faqAccordion .accordion-button:hover {
  text-decoration: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
}

.accordion-button {
  transition: background-color 0.3s ease;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  font-family: 'FontAwesome';
  background-image: none;
}

.accordion-button.arrow-open::after {
  content: '\f078';
}

.accordion-button.arrow-closed::after {
  content: '\f077';
}
@media screen and (max-width: 767px) {
  #faqAccordion .accordion-button {
    font-size: 1.2rem;
  }
}
</style>
